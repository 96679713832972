.title-list-of-articles {
    color: #65328F;
    font-weight: 700;
    text-align: center;
}

.article-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 50px 60px;
}

.article-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;
}

.article-page-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px;
}

.article-card {    
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 310px;
    padding: 20px;
    border: 2px solid transparent;
    outline: 2px solid rgba(51, 51, 51, 0.2);
    outline-offset: -2px;
    box-shadow: 0 0 0 2px #333333;
    border-radius: 15px;
    box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.article-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 5px;
}

.article-card-title {
    display: flex;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
}

.article-title {
    display: flex;
    font-weight: bold;
    font-size: 40px;
    width: 100%;
}

.article-desc {
    display: flex;
    font-weight: normal;
    font-size: 12px;
    width: 100%;
    text-align: justify;
}

.article-content-table {
    display: flex;
    font-weight: 200;
    font-size: 22px;
    width: 100%;
}

.article-subtitle {
    display: flex;
    font-weight: bolder;
    font-size: 20px;
    width: 100%;
    padding: 20px 15px;
}

.article-author {
    display: flex;
    font-weight: 400;
    font-size: 20px;
    width: 100%;
}

.article-head {
    display: flex;
    font-size: 20px;
    width: 100%;
    word-wrap: break-word;
}

.article-text {
    display: flex;
    font-size: 20px;
    width: 100%;
    word-wrap: break-word;
}

.pagination {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.pagination-button.active {
    background-color: #a6a6a6;
    color: #fff;
}

.pagination-blank {
    display: flex;
    justify-content: center;
    padding: 8px 0;
}

.article-details-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px;
}

@media (max-width: 768px) {
    .article-details-section {
        flex-direction: column;
        align-items: center;
    }

    .another-articles-container {
        width: 100%;
        margin-top: 20px;
    }
}

.article-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
    padding: 70px 20px;
}

.another-articles-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 25%;
    padding: 50px 20px;
}

.article-loading-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.wrapper {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 15px;
}

.wrapper img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.another-article-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border: 2px solid transparent;
    outline: 2px solid rgba(51, 51, 51, 0.2);
    outline-offset: -2px;
    box-shadow: 0 0 0 2px #333333;
    border-radius: 15px;
    box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.another-article-title {
    display: flex;
    font-weight: bolder;
    font-size: 16px;
    text-decoration: underline;
    width: 100%;
    color: #707070;
}

.another-article-content {
    display: flex;
    font-weight: normal;
    font-size: 14px;
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: center;
}

.button-container button {
    background-color: #FBC213;
    color: #65328F;
    font-weight: bold;
    border-radius: 8px;
    padding: 10px 17px;
    font-size: 13px;
    border: none;
    transition: transform 0.3s;
}

.button-container button:hover {
    transform: scale(0.95);
}

.button-container button:active {
    transform: scale(0.9);
}