.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; 
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-box {
  background-color: white; 
  width: 100%; 
  height: 80%;
  max-width: 700px;
  border-radius: 8px; 
  padding: 20px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
  overflow-y: auto;
}
.container-modal{
    display: flex;
    flex-wrap: nowrap;
}
.left{
    width: 50%;
}
.right{
    width: 50%;
}
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}
.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}
.modal-button {
    border: 0px;
    background-color: #fefefe;
    text-align: left;
    margin: 10px;
    padding: 8px 16px; 
    border-radius: 4px; 
    transition: background-color 0.3s;

    /* Hover effect */
    &:hover {
        background-color: #f0f0f0; /* Darker background color on hover */
    }

    /* Active (clicked) state */
    &:active{
        background-color: #e0e0e0; /* Even darker background color when clicked */
    }
    &:focus {
        background-color: #F9DD89
    }
}

.form-group {
    margin-bottom: 20px;
}
.form-group label {
    font-size: 14px;
}
.form-group input[type="text"]::placeholder {
    font-size: 14px;
}
.form-group input[type="text"] {
    width: 250px; 
    border-radius: 8px; 
    padding: 10px;
}
.button-submit-modal{
    background-color: #FBC213; /* Button background color */
    color: #65328F; /* Text color */
    font-weight: 600;
    border: none;
    padding: 10px 20px; /* Padding */
    border-radius: 4px; /* Border radius */
    cursor: pointer; /* Cursor style */
    transition: background-color 0.3s; /* Smooth transition for background color change */

    /* Hover effect */
    &:hover {
        background-color: #F9DD89; /* Darker background color on hover */

         /* Remove border */

    }

    /* Active (clicked) state */
    &:active,
    &:focus {
        background-color: #fca904; /* Even darker background color when clicked */
    }
}


  
 

