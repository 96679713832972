.container {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
}

.left-column-sec {
    width: 100%; 
    order: 1; 
}

.right-column-sec {
    width: 100%; 
    order: 2; 
}

.middle-2 {
    width: 50%; 
    margin: 0 auto; /* Center the element horizontally */
}

@media screen and (min-width: 1190px) {
    .container {
        flex-wrap: nowrap; 
    }

    .right-column-sec {
        width: 40%; 
        order: 2;
    }

    .left-column-sec {
        width: 60%;
        order: 1;
    }

    .middle-2 {
        width: 100%; 
        margin: 0 auto; /* Center the element horizontally */
    }
}

.background-section-2 {
    display: flex;
    justify-content: center;
}

.background-section-2 img {
    margin-top: 150px;
    width: 400px;
    height: auto;
}

.square-cont {
    display: flex;
}

.word {
    font-size: 12px; 
    margin: 3px; 
    padding: 5px; 
    background-color: #65328F; 
    color: white; 
    border-radius: 1px; 
}

.h3-cont h3 {
    margin: 0;
    display: block;
    font-weight: bold;
}

.h3-cont {
    width: 100%; /* Adjust width to be responsive */
    margin: 0 auto; 
    text-align: center;
}

.slide-image {
    width: 50%; 
    height: auto;
    max-height: auto; 
    display: block; 
    margin: 0 auto; 
    margin-bottom: 10px;
}

.slider-container {
    width: 80%;
    max-width: 300px; 
    max-height: 200px;
    margin: 0 auto; 
    margin-top: 10px;
    text-align: center;
}

.image-cont {
    border: 3px solid #65328F;
    border-radius: 10px;
    width: 320px;
    height: 400px;
}

.slider-sets {
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.image-cont h4 {
    margin-top: 40px;
}

.image-cont li {
    font-weight: 640;
}

.image-cont p {
    font-weight: 640;
}

@media screen and (min-width: 1190px) {
    .slider-container {
        width: 80%;
        max-width: 300px; 
        max-height: 200px;
        margin: 0 auto; 
        margin-top: 10px;
        text-align: center;
    }
}
