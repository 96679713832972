.title-list-of-referensi {
    color: #65328F;
    font-weight: 700;
    text-align: center;
}

.referensi-container {
    text-align: center;
    padding-bottom: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.slider {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.slider button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    margin: 5px 5px;
    padding-bottom: 2px;
}

.slider button.active {
    border-bottom: 2px solid #65328F;
}