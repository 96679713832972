.title-list-of-services {
    color: #65328F;
    font-weight: 700;
    text-align: center;
}

.services-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 50px 60px;
}

.services-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 310px;
    height: 300px;
    padding: 30px;
    border: 2px solid transparent;
    outline: 2px solid rgba(51, 51, 51, 0.2);
    outline-offset: -2px;
    box-shadow: 0 0 0 2px #333333;
    border-radius: 15px;
    box-shadow: -5px 5px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
}

.button-container {
    display: flex;
    justify-content: center;
}

.button-container button {
    background-color: #FBC213;
    color: #65328F;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px 17px;
    font-size: 13px;
    border: none;
    transition: transform 0.3s;
}

.button-container button:hover {
    transform: scale(0.95);
}

.button-container button:active {
    transform: scale(0.9);
}