.feedback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.column {
    flex: 1;
}

.video-container {
    background-color: #FBC213;
    padding: 40px;
}

.testimonial-container {
    width: 100%;
    background-color: #fff;
    margin-left: 40px;
    text-align: start;
}

.date {
    color: #5A6684;
}

.title-testimony {
    text-align: start;
    padding: 10px 40px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .feedback-container {
        flex-direction: column;
        align-items: center;
    }

    .column {
        width: 100%;
        margin-bottom: 20px;
    }
}
