.Footer {
    padding: 20px 120px;
    background: #FAFAFA;
  }
  
.ft-1 p {
    font-weight: 500;
    margin-bottom: 0px;
    margin-left: 20px;
}

.footer-icons i {
    padding: 0.4rem 0.5rem;
    background: #FAFAFA;
    color: #65328F;
    margin: 20px 15px;
    border-radius: 50%;
    font-size: 30px;
}

.footer-icons i:hover {
    background: #65328F;
    color: #fff;
    transition: 0.6s;
    cursor: pointer;
}

.Footer h5 {
    color: #000000;
    font-size: 20px;
    font-weight: 650;
    margin-left: 20px;
}


.footer-logo {
    display: flex;
    width: 137px;
    height: 40px;
    padding: 0px 12px 0px 11px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}