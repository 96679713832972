.custom-card.card-nilai.card {
    width: 336px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    margin: 20px;
    border-radius: 15px;
    border-color: aliceblue;
}

.card-info-number {
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 20px;
    color: #FBC213;
}

.card-info-title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 20px;
}

.card-info-description {
    display: flex;
    align-items: center;
}

.card-info-description span {
    font-size: 14px;
}


.card-info span {
    font-size: 14px;
}
