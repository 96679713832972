.title-tentang-kami {
    color: #65328F;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30pxg;
}

.tentang-kami-container {
    text-align: center;
    padding-bottom: 20px;
    padding: 100px;
    text-align: center;
}

