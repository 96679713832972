.title-list-of-careers {
    color: #65328F;
    font-weight: 700;
    text-align: center;
}

.career-container {
    text-align: center;
    padding-bottom: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.card-career {
    margin: 20px;
    max-width: calc(33.33% - 40px);
    flex-basis: calc(33.33% - 40px);
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.button-container button {
    align-items: center;
    background-color: #FBC213;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    font-size: 13px;
    color: #65328F;
    font-weight: 700;
    border: 1px solid #65328F;
    transition: transform 0.3s;
}

.button-container button:hover {
    transform: scale(0.95);
}

.button-container button:active {
    transform: scale(0.9);
}

@media only screen and (max-width: 768px) {
    .card-career {
        max-width: calc(50% - 40px);
        flex-basis: calc(50% - 40px);
    }
}

@media only screen and (max-width: 480px) {
    .card-career {
        max-width: calc(100% - 40px);
        flex-basis: calc(100% - 40px);
    }
}
