.nilai-perusahaan-container {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.title-nilai-perusahaan{
    color: #65328F;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

