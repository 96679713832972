.container {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
}

.left-column-thr {
    width: 100%; 
    order: 2; 
}

.right-column-thr {
    width: 100%; 
    order: 1; 
}

.middle {
    width: 55%; 
    margin: 0 auto; 
}

@media screen and (min-width: 1190px) {
    .container {
        flex-wrap: nowrap; 
    }

    .left-column-thr {
        width: 60%;
        order: 1; 
    }

    .right-column-thr {
        width: 40%; 
        order: 2; 
    }

    .middle {
        width: 55%; 
        margin: 0 auto; 
    }
}

.background-section-3 {
    display: flex;
    justify-content: center;
}

.background-section-3 img {
    margin-top: 150px;
    width: 400px;
    height: auto;
}

.square-cont-2 {
    display: flex;
}

.h3-cont-2 {
    width: 55%; 
    margin: 0 auto; 
    text-align: center; /* Align h3 center */
}

.h3-cont-2 h3 {
    margin: 0;
    display: block;
    font-weight: bold;
}

.slider-container-2 {
    width: 80%;
    max-width: 300px; 
    max-height: 200px;
    margin: 0 auto; 
    margin-top: 10px;
    text-align: center;
}
