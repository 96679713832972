.box {
    width: 90%; /* Adjust width to be responsive */
    max-width: 1440px; /* Set maximum width */
    min-height: 251px; /* Adjust to fit content */
    background: linear-gradient(3deg, #F9DD89 30%, #FBC213 50%);
    margin: 0 auto;
    border-radius: 10px;
    overflow: hidden; /* Hide overflow content */
}


.container-about {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap */
    margin: 10px 5%; /* Adjust margins to be responsive */
}

.left-column-about,
.right-column-about {
    flex: 1; /* Take up equal space */
    min-width: 50%; /* Minimum width for responsiveness */
}

.p {
    font-weight: 600;
    font-size: 19px;
}

.h2-cont {
    display: flex;
}

.h2-cont h2 {
    margin-top: 40px;
    margin-left: 5%; /* Adjust margins to be responsive */
}

.button-daftar-about {
    flex-basis: 30%; /* Adjust width to be responsive */
    max-width: 100px; /* Set maximum width */
    color: #65328F;
    background-color: white;
    border-radius: 8px;
    border-color: #65328F;
    margin-right: 10px;
    border-width: 1px;
    font-weight: bolder;
}

.button-sales-about {
    flex-basis: 40%; /* Adjust width to be responsive */
    max-width: 140px; /* Set maximum width */
    color: white;
    background-color: #65328F;
    border-radius: 8px;
    border: none;
    margin-right: 10px;
    font-weight: bolder;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
    .container-about {
        flex-direction: column; /* Change to column layout on smaller screens */
    }

    .left-column-about,
    .right-column-about {
        min-width: 100%; /* Occupy full width on smaller screens */
    }
}
