.navbar-custom {
    background: #FAFAFA;
}

.nav-item {
    color: rgb(0, 0, 0);
    font-size: 13px;
    font-weight: 700;
}

.button-contact {
    align-items: center;
    background-color: #FBC213;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    font-size: 13px;
    color: #65328F;
    font-weight: 700;
    border: 1px solid #65328F;
  }

  .brand img {
    display: flex;
    width: 137px;
    height: 40px;
    padding: 0px 12px 0px 11px;
    justify-content: center;
    align-items: center;
  }