.hero-container {
    background-color: #65328F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 528px;
    margin: 0 auto;
    padding: 100px;
    box-sizing: border-box;
}

.title-hero {
    font-weight: 700;
    color: #fff;
}

.description {
    color: #fff;
}

.title-hero {
    margin-bottom: 40px;
}

.button-container-hero {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
}

.button-container-hero button {
    align-items: center;
    background-color: #FBC213;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    font-size: 13px;
    color: #65328F;
    font-weight: 700;
    border: 1px solid #65328F;
    transition: transform 0.3s;
}

.button-container-hero button + button {
    margin-left: 10px;
}

.button-container-hero button:last-child {
    background-color: #fff;
    color: #65328F;
    border: 1px solid #65328F;
    margin-left: 20px;
}

.button-container-hero button:hover {
    transform: scale(0.95);
}

.button-container-hero button:active {
    transform: scale(0.9);
}

.slick-dots {
    bottom: 20px;
  }
  
.slick-dots li button:before {
    color: white;
}

.slick-dots li.slick-active button:before {
    color: #FBC213;
}

@media (max-width: 768px) {
    .hero-container {
        padding: 20px;
    }
}