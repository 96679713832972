.container {
    display: flex;

}

.left-column {
    width: 100%; /* Occupy full width by default */
    order: 2; /* Default order, left column comes after right column */
}

.right-column {
    width: 100%; /* Occupy full width by default */
    order: 1; /* Default order, right column comes before left column */
}

@media screen and (min-width: 786px) {
    .container {
        flex-wrap: nowrap; /* Prevent wrapping on larger screens */
    }

    .left-column {
        width: 60%; /* Restore original width for left column */
        order: 1; /* Place left column after right column on larger screens */
    }

    .right-column {
        width: 40%; /* Restore original width for right column */
        order: 2; /* Place right column before left column on larger screens */
    }
}
.background-section{
    display: flex;
    justify-content: center;
}

.background-section img{
    width: 400px;
    height: auto;
    margin-bottom: 100px;
    margin-top: 100px;

}

.text-1 {
    width: 50%; 
    margin: 0 auto; 
    padding: 20px;
    text-align: justify;
}


.image-1 img{
    width: 130px;
    height: auto;
    margin-left: 40px;
}
.container-2 {
    display: flex;
    flex-direction: column; /* Display items vertically */

}
@media screen and (min-width: 768px) {
    .container-2 {
        flex-direction: row; /* Display items horizontally on larger screens */
    }

    .image-1 img{
        width: 130px;
        height: auto;
        margin-right: 40px;
        margin-left: 0px;
    }
    .background-section img{
        width: 400px;
        height: auto;
        margin-bottom: 100px;
        margin-top: 100px;
    }

}

.container-3{
    display: flex;
    justify-content: space-between; 
    align-items: flex-end; 
}
.p-rp{
    font-size: 10px;
    margin-right: 5px;

}
.p-harga{
    font-size: 10px;
    margin-bottom: 0;
}

.h-harga{
    font-size: 50px;
}

.allign-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    order: 1;
}




.button-sales{
    width:140px;
    color: #65328F;
    background-color: white;
    border-radius: 8px;
    border-color: #65328F;
    margin-right: 10px;
    border-width: 1px;
    font-weight: bolder;
}

.button-daftar{
    width: 75px;
    color:white;
    background-color: #65328F;
    border-radius: 8px;
    border: none;
    margin-right: 10px;

}

.button-container-1 {
    display: flex;
    justify-content: center;
}
.button-container-1 button {
    font-weight: bold;
    transition: transform 0.3s;
}
.button-container-1 button:hover {
    transform: scale(0.95);
}

.button-container-1 button:active {
    transform: scale(0.9);
}