.custom-card {
    width: 335px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
}

.custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-career-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
}

.card-info-time {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.card-info-time img {
    margin-right: 10px;
}

.card-info-location {
    display: flex;
    align-items: center;
}

.card-info-location img {
    margin-left: 4px;
    margin-right: 15px;
}

.card-info-location span {
    font-size: 14px;
}

.card-info-time span {
    font-size: 14px;
}

.onsite {
    margin-top: 25px;
    display: inline-block;
    color: #AA8000;
    font-size: 12px;
    background-color: rgba(251, 194, 19, 0.43);
    padding: 4px 15px;
    border-radius: 10px;
}
