.container-career-detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 40px;
}

.career-info-title {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 10px;
}

.position-title {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 10px;
}

.career-detail-card {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 50%;
    padding: 0 20px;
}

.career-info-time {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.career-info-time img {
    margin-right: 10px;
}

.career-info-location {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.career-info-location img {
    margin-left: 4px;
    margin-right: 15px;
}

.career-info-location span {
    font-size: 14px;
}

.career-info-time span {
    font-size: 14px;
}