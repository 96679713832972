.title-list-of-event {
    color: #65328F;
    font-weight: 700;
    text-align: center;
}

.event-container {
    text-align: center;
    padding-bottom: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.slick-slide img {
    margin: 80px auto;
}
