.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px;
    max-width: 100%;
}

.logo {
    width: 550px;
    height: auto;
    margin: 10px 0;
}

.text {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.button-container button {
    align-items: center;
    background-color: #FBC213;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 8px 16px;
    position: relative;
    font-size: 13px;
    color: #65328F;
    font-weight: 700;
    border: 1px solid #65328F;
    transition: transform 0.3s;
}

.button-container button:hover {
    transform: scale(0.95);
}

.button-container button:active {
    transform: scale(0.9);
}
